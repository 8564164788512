import React, { memo } from 'react';
import './SectionTitle.css'

const SectionTitle = (props) => {
    const subTitle = props.subTitle ? <p className="text-uppercase mb-0">{props.subTitle}</p> : ''
    return (
        <header className="section-header text-center">
            <h3>{props.title}</h3>
            {subTitle}
        </header>
    )
};

export default memo(SectionTitle);