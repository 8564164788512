import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SectionTitle from '../SectonTitle'
import Bounce from 'react-reveal/Bounce';

import './WhyUs.css';

const data = [
    {
        icon: 'far fa-gem',
        title: 'VALUE OUR CUSTOMER',
        description: `
        We appreciate and respect the time and effort of our customers. 
        We work with great zeal and handle it with transparency
        `
    },
    {
        icon: 'fas fa-shield-alt',
        title: 'PROFESSIONALISM',
        description: `We believe in excellent quality and never lower our standards with respect to time. 
        We believe in doing and not saying also doing right in the first go.`
    },
    {
        icon: 'far fa-paper-plane',
        title: 'QUALITY VS TIME',
        description: 'We never make a compromise on quality against time. We strongly believe in "Do-it-right-the-first-time" ! And we never promise what we can not do !'
    }
]
const WhyUs = () => (
    <section id="whyus">
        <Container>
            <SectionTitle
                title="Why Choose Us ?"
                subTitle="3 Reasons to choose us"
            />
            <Bounce bottom>
                <Row>
                    {
                        data.map(({ title, icon, description }, i) => (
                            <Col key={`${i}`} sm={4} className="mb-4">

                                <div className="card">
                                    <i className={icon}></i>
                                    <div className="card-body">
                                        <h5 className="card-title text-white">{title}</h5>
                                        <p className="card-text">{description}</p>
                                    </div>
                                </div>

                            </Col>
                        ))
                    }
                </Row>
            </Bounce>
        </Container>
    </section>

);

export default WhyUs;