import React from 'react';
import { Row, Col, Form, FormGroup, Input, Button } from 'reactstrap';
import Fade from 'react-reveal/Fade';

import SectionTitle from '../SectonTitle'
import './ContactUs.css';

const Info = ({ className, children }) => (
    <Col xs={12} sm={4} className="info">
        <i className={className}></i>
        <p className="ml-1">{children}</p>
    </Col>
)

const ContactUs = () => (
    <section id="contact-us">
        <SectionTitle title="Contact Us" />
        <Fade bottom>

            <Row className="content">
                <Col sm={6} className="mb-4">
                    <iframe className="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.3633503931633!2d77.70418061388354!3d13.012518190829034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11ac1ee33c3b%3A0x5979ef9cd7be584e!2s3rd%2C+33%2C+16th+Cross+Rd%2C+Chikkabasavanapura%2C+Krishnarajapura%2C+Bengaluru%2C+Karnataka+560036!5e0!3m2!1sen!2sin!4v1564446005046!5m2!1sen!2sin" allowFullScreen width="600" title="KodeStack Location Map" frameBorder="0"></iframe>
                </Col>
                <Col xs={12} sm={6} className="mb-4">
                    <Row>
                        <Info className="fas fa-map-marker-alt">
                            No.33/, 16th cross, 3rd Stage, Basavanapura, K.R Puram, Bengalore-560036.
                        </Info>
                        <Info className="far fa-envelope"><a href="mailto:info@kodestack.com">info@kodestack.com</a></Info>
                        <Info className="fas fa-phone-alt"><a href="tel:+919498002109">+91 94980 02109</a></Info>
                    </Row>
                    <Row>
                        <Col>
                            <Form className="form">
                                <Row form>
                                    <Col sm={6} md={6}>
                                        <FormGroup>
                                            <Input type="text" name="name" id="username" placeholder="Your Name" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={6} md={6}>
                                        <FormGroup>
                                            <Input type="email" name="email" id="exampleEmail" placeholder="Your Email" />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Input type="text" name="subject" id="subject" placeholder="Subject" />
                                </FormGroup>
                                <FormGroup>
                                    <Input type="textarea" name="text" rows="5" id="message" placeholder="message" />
                                </FormGroup>
                                <div className="text-center">
                                    <Button type="submit" className="submit-btn">Submit</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fade>

    </section>
);

export default ContactUs;