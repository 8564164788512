import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-scroll'

import './Footer.css';

const SocialLinks = () => (
    <div className="social-links">
        <a href="/" className="twitter"><i className="fab fa-twitter"></i></a>
        <a href="/" className="facebook"><i className="fab fa-facebook-f"></i></a>
        <a href="/" className="instagram"><i className="fab fa-instagram"></i></a>
        <a href="/" className="google-plus"><i className="fab fa-google-plus-g"></i></a>
        <a href="/" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
    </div>
);

const CopyRightSection = () => (
    <div className="copyright-band">
        <Container>
            <Row>
                <Col>
                    &copy; {new Date().getFullYear()} Copyright <b>KodeStack</b>. All Rights Reserved
                </Col>
            </Row>
        </Container>
    </div>
);

const Footer = () => {
    return (
        <footer className="footer wow fadeIn text-white">
            <div className="footer-top">
                <Container>
                    <Row>
                        <Col lg="4" md="6" className="footer-info">
                            <h3>KodeStack</h3>
                            <p className="my-0 font-montserrat">
                                In the present scenario i.e. the ever growing and changing environment,
                                our professional consultants at Kodestack will do anything it takes
                                to do the right work at the right time and provide innovative solutions
                                with a significant return on your investment.
                            </p>
                        </Col>
                        <Col lg="4" md="6" className="footer-links d-md-flex justify-content-md-center">
                            <div>
                                <h4 className="mt-5 mt-sm-0">Usefull Links</h4>
                                <ul className="p-0 m-0">
                                    <li><a href="/">Home</a></li>
                                    <li>
                                        <Link offset={-70} duration={1000} to="about-kodestack" spy={true} smooth="easeInOutQuart" href="/">About Us</Link>
                                    </li>
                                    <li>
                                        <Link offset={-70} duration={1000} to="kodestack-services" spy={true} smooth="easeInOutQuart" href="/">Services</Link>
                                    </li>
                                    {/* <li><a href="/">Terms of service</a></li>
                                <li><a href="/">Privacy policy</a></li> */}
                                </ul>
                            </div>
                        </Col>
                        <Col lg="4" md="6" className="footer-address">
                            <h4 className="mt-5 mt-sm-0">Contact us</h4>
                            <p>
                                No.33/, 16th cross,<br />
                                Gayathri Layout, 3rd Stage,<br />
                                Basavanapura Main Road, K.R Puram,<br />
                                Bengalore-560036.<br />
                                <strong>Phone:</strong> <a className="text-white" href="tel:+919498002109">+91 94980 02109</a><br />
                                <strong>Email:</strong> <a className="text-white" href="mailto:info@kodestack.com">info@kodestack.com</a><br />
                            </p>
                            <SocialLinks />
                        </Col>
                    </Row>
                </Container>
            </div>
            <CopyRightSection />
        </footer>
    )
}

export default Footer;