import React, { useState, useEffect, memo } from 'react';
import { Button, Navbar, Container } from 'reactstrap';
import { Link } from 'react-scroll'

import './NavTop.css';

const NavToggleButton = memo(({ menuVisibility, toggleMobileNav }) => {
    if (menuVisibility) {
        return (
            <Button className="navbar-toggler open" onClick={toggleMobileNav}>
                <i className="fas fa-times"></i>
            </Button>
        )
    }

    return (
        <Button className="navbar-toggler" onClick={toggleMobileNav}>
            <i className="fas fa-bars"></i>
        </Button>
    )
});

const NavItems = memo(({ menuVisibility, toggleMobileNav }) => {

    const menuItems = [
        ['Home', 'intro'],
        ['About Us', 'about-kodestack'],
        ['Services', 'kodestack-services'],
        ['Contact Us', 'contact-us'],
    ];

    const getWraperClasses = () => {
        const defaultClasses = 'navbar-nav-wraper d-flex align-items-center justify-content-center';
        return menuVisibility ? `${defaultClasses} open` : defaultClasses;
    };

    return (
        <div className={getWraperClasses()}>
            <ul className="navbar-nav text-center text-white font-montserrat">
                {
                    menuItems.map(([text, name], i) => {
                        return (
                            <li className="nav-item" key={`${i}`}>
                                <Link activeClass="active" className="nav-link px-3" onClick={toggleMobileNav} offset={-70} duration={1000} to={name} spy={true} smooth="easeInOutQuart" href="/">{text}</Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
});

const NavTop = () => {
    const [menuVisibility, setMenuVisibility] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const doc = document.documentElement;
        const handleScroll = () => {
            if (doc.scrollTop > 100 && !scrolled) {
                setScrolled(true);
                return;
            }

            if (doc.scrollTop < 100 && scrolled) {
                setScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [scrolled]);

    const toggleMobileNav = () => setMenuVisibility(!menuVisibility); // Menu Visibility control on mobile devices

    return (
        <header className={`bg-white fixed-top ks-header${scrolled ? ' ks-scrolled' : ''}`}>
            <Navbar expand="md" className="bg-white h-100">
                <Container>
                    <a className="navbar-brand font-montserrat py-0" href="/">
                        <img src="/images/logo.svg" className="d-inline-block align-top navbar-brand-logo" alt="" />
                        {/* KodeStack */}
                    </a>

                    <NavToggleButton menuVisibility={menuVisibility} toggleMobileNav={toggleMobileNav} />

                    {/* Nav Links */}

                    <div className="collapse navbar-collapse justify-content-md-end">
                        <NavItems menuVisibility={menuVisibility} toggleMobileNav={toggleMobileNav} />
                    </div>
                </Container>
            </Navbar>
        </header>
    )
};


export default NavTop;