import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-scroll'

import './LandingBanner.css';

const LandingBanner = () => (
    <section className="landing-banner" id="intro">
        <Container>
            <Row>
                <Col sm={{ size: 6, order: 2 }}>
                    <img className="img-fluid" alt="About Us" decoding="async" src="/images/intro-img.svg" />
                </Col>
                <Col sm={6}>
                    <h2 className="text-white font-weight-bold">We provide<br /><span>solutions</span><br />for your business!</h2>
                    <div>
                        <Link className="intro-btn d-inline-block text-white btn-getstarted" offset={-70} duration={1000} to="about-kodestack" spy={true} smooth="easeInOutQuart" href="/">About Us</Link>
                        <Link className="intro-btn d-inline-block text-white btn-services" offset={-70} duration={1000} to="kodestack-services" spy={true} smooth="easeInOutQuart" href="/">Our Services</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
);

export default LandingBanner;