import React from 'react';
import './App.css';
import HomePage from './pages/Home';

function App() {
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
