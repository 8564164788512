import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import SectionTitle from '../SectonTitle'
import './AboutUs.css';

const AboutUs = () => (
    <section id="about-kodestack">
        <SectionTitle
            title="About Us"
            subTitle="Some things that you should know about us"
        />
        <Container>
            <Row className="content-row">
                <Col xs={{ size: 12, order: 2 }} sm={6} className="content">
                    <h4 className="font-weight-bold">A LITTLE ABOUT US</h4>
                    <p>
                        Initiated by highly experienced and well quipped software professionals,
                        We at Kodestack aim to provide expertise development solutions and services in
                        accordance with the requirements of your company and its perspectives.
                        A group of young and energetic professionals with the main motive of providing varied
                        range of development solutions with the highest level of satisfaction of our customers.
                   </p>
                </Col>
                <Col xs={{ size: 12, order: 1 }} sm={{ size: 6, order: 2 }} className="img-container">
                    <Fade bottom>
                        <img src="/images/about-img.svg" alt="About Us" decoding="async" className="w-100" />
                    </Fade>
                </Col>
            </Row>
        </Container>
    </section>
);

export default AboutUs;