import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Bounce from 'react-reveal/Bounce';

import SectionTitle from '../SectonTitle'

import './Services.css';

const data = [
    {
        icon: 'icon ion-ios-globe',
        iconColor: '#ff689b',
        title: 'Web Development',
        content: `Our expertise in developing robust enterprise web applications 
        with mobility and using cutting-edge technology.`
    },
    {
        icon: 'icon ion-ios-phone-portrait',
        iconColor: '#e9bf06',
        title: 'Mobile Apps',
        content: `We create versatile mobile application for your business which runs in 
        different platforms`
    }
];
const Services = () => (
    <section id="kodestack-services">
        <SectionTitle
            title="Services"
            subTitle="We are experts in digitizing your business"
        />
        <Container className="service-card-container">
            <Bounce bottom>
                <Row className="justify-content-center">
                    {
                        data.map(({ icon, iconColor, title, content }, i) => (
                            <Col key={`${i}`} sm={{ size: 5 }}>
                                <div className="service-card position-relative bg-white">
                                    <div className="icon-container position-absolute">
                                        <i className={icon} style={{ color: iconColor }}></i>
                                    </div>
                                    <div className="ml-5">
                                        <h4 className="text-center font-weight-bold">{title}</h4>
                                        <p>{content}</p>
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </Row>
            </Bounce>
        </Container>
    </section>
);

export default Services;