import React from 'react';
// import Header from '../../components/Header';
import LandingBanner from '../../components/LandingBanner';
import AboutUs from '../../components/AboutUs';
import WhyUs from '../../components/WhyUs';
import ContactUs from '../../components/ContactUs';
import Footer from '../../components/Footer';
import Services from '../../components/Services';
import NavTop from '../../components/NavTop';

const HomePage = () => (
    <>
        {/* <Header /> */}
        <NavTop/>
        <LandingBanner />
        <AboutUs />
        <Services/>
        <WhyUs />
        <ContactUs />
        <Footer />
    </>
);

export default HomePage;